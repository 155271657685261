import { formatDate } from "~/lib/utils";

export function episodeShortName(
  episode: { episodeKey: string; untilTime: Date },
  user: { locale: string; timeZone: string } | null,
) {
  return formatDate(episode.untilTime, user, {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
}

export function episodeLongName(
  episode: { episodeKey: string; untilTime: Date },
  user: { locale: string; timeZone: string } | null,
) {
  const date = formatDate(episode.untilTime, user, {
    month: "long",
    day: "numeric",
  });
  return `Sumcast, ${date}`;
}

export function nextEpisodePublishDay(
  { untilTime }: { untilTime: Date },
  user: { locale: string; timeZone: string } | null,
  noOnPrefix = false,
) {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (untilTime.toDateString() === today.toDateString()) {
    return "today";
  }
  if (untilTime.toDateString() === tomorrow.toDateString()) {
    return "tomorrow";
  }
  return `${noOnPrefix ? "" : "on "}${formatDate(untilTime, user, {
    weekday: "long",
    month: "long",
    day: "numeric",
  })}`;
}
